import Layout from "../../components/Layout/Layout";

const ProductsPage = () => {
    return(
        <Layout>
            <div> Products page</div>
        </Layout>
    )
}

export default ProductsPage;
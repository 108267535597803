import Slider from "react-slick";
import { testimonialData, testimonialSliderSettings } from "../../constants";


const TestimonialSection = () => {
  return(
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                    <p className="fs-5 fw-bold text-primary">Testimonial</p>
                    <h1 className="display-5 mb-5">What Our Clients Say About Us!</h1>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.</p>
                    <a className="btn btn-primary py-3 px-4" href="/">See More</a>
                </div>
                <div className="col-lg-7 wow fadeInUp testimonial-slider" data-wow-delay="0.5s">
                  <Slider {...testimonialSliderSettings}>
                    {
                      testimonialData.map((data) => {
                        return(
                          <div key={data.key} className="testimonial-item">
                            <img className="img-fluid rounded mb-3" src={data.image} alt="" />
                            <p className="fs-5">{data.clientMessage}</p>
                            <h4>{data.clientName}</h4>
                            <span>{data.clientProfession}</span>
                          </div>
                        )
                      })
                    }
                    </Slider>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TestimonialSection
import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';

const ProductsDetailPage = (props) => {
    const { id } = useParams();
    console.log(id)
    return(
        <Layout>
            <div> Products detail page</div>
        </Layout>
    )
}

export default ProductsDetailPage;
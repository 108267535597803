import { FaPhone, FaEnvelope, FaSquareXTwitter, FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTelegram, FaXmark, FaAward, FaUsers } from "react-icons/fa6";
import { GiWaterTower, GiLaptop, GiWaves, GiDoubleFish, GiFishingBoat, GiModernCity  } from "react-icons/gi";
import Slider1 from "./assets/images/slider1.jpg";
import Slider2 from "./assets/images/slider2.jpg";
import Slider3 from "./assets/images/slider3.jpg";
import Slider4 from "./assets/images/slider4.jpg";
import Product2 from "./assets/images/product2.jpg";
import Product5 from "./assets/images/product5.jpg";
import Product3 from "./assets/images/coastal-monitering-system.jpg";
import Team1 from "./assets/images/team-1.jpg";
import Team2 from "./assets/images/team-2.jpg";
import Team3 from "./assets/images/team-3.jpg";
import Testimonial1 from "./assets/images/testimonial-1.jpg"
import Testimonial2 from "./assets/images/testimonial-2.jpg"
import Blog1 from "./assets/images/blog/blog-bg-1.jpg"
import Blog2 from "./assets/images/blog/blog-bg-2.jpg"
import Blog3 from "./assets/images/blog/blog-bg-3.jpg"
import Blog4 from "./assets/images/blog/blog-bg-4.jpg"
import Blog5 from "./assets/images/blog/blog-bg-5.jpg"
import Blog6 from "./assets/images/blog/blog-bg-6.jpg"


export const contactData = [
	{
		key: "1", icon: <FaPhone />, class: "h-100 d-inline-flex align-items-center me-4", data: "+91 8128181286", link: "https://wa.me/8128181286"
	},
	{
		key: "2", icon: <FaEnvelope />, class: "h-100 d-inline-flex align-items-center", data: "info@dexterousdynamics.com", link: "mailto:info@dexterousdynamics.com"
	},
]

export const socialMediaData = [
	{
		key: "1", icon: <FaFacebookF />, link: "https://www.facebook.com/dexterousdynamics/"
	},
	{
		key: "2", icon: <FaSquareXTwitter />, link: "https://x.com/i/flow/login?redirect_after_login=%2FDexterousDyna"
	},
	{
		key: "3", icon: <FaLinkedinIn />, link: "https://www.linkedin.com/company/dexterousdynamics"
	},
	{
		key: "4", icon: <FaInstagram />, link: "https://www.instagram.com/dexterousdynamics"
	},{
		key: "5", icon: <FaYoutube />, link: "https://youtube.com/@dexterousdynamics8439?si=VU5SapzM9d7uqIJt"
	},
	{
		key: "6", icon: <FaTelegram />, link: "https://t.me/dexterousdynamics"
	},
]

export const sliderData = [
	{
		key: "1", image: Slider1, textClass: "col-lg-8", headingText: "Innovate | Monitor | Sustain", buttonName: "Explore More", buttonLink: "/"
	},
	{
		key: "2", image: Slider2, textClass: "col-lg-7", headingText: "We bring electronics to life", buttonName: "Explore More", buttonLink: "/"
	},
	{
		key: "3", image: Slider3, textClass: "col-lg-8", headingText: "Innovate | Monitor | Sustain", buttonName: "Explore More", buttonLink: "/"
	},
	{
		key: "4", image: Slider4, textClass: "col-lg-7", headingText: "We bring electronics to life", buttonName: "Explore More", buttonLink: "/"
	},
]

export const sliderSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	fade: true,
	  cssEase: 'linear',
	arrows: true,
	autoplaySpeed: 100,
	prevArrow: <button type="button" class="slick-custom-arrow slick-prev">
		<div class="arrow arrow--left">
			<span></span>
		</div>
	</button>,
	nextArrow: <button type="button" class="slick-custom-arrow slick-next">
		<div class="arrow arrow--right">
			<span></span>
		  </div> 
	</button>
};

export const topFeaturesData = [
	{
		key: "1", icon: <FaXmark size={20} className="text-primary" />, title: "Innovate", description: "Pioneering advanced solutions for a smarter, more efficient future.", delay: "0.1s"
	},
	{
		key: "2", icon: <FaUsers size={20} className="text-primary" />, title: "Monitor", description: "Real-time environmental data for precise control and decision-making.", delay: "0.3s"
	},
	{
		key: "3", icon: <FaPhone size={15} className="text-primary" />, title: "Sustain", description: "Ensuring long-term impact through eco-friendly and sustainable practices.", delay: "0.5s"
	}
]

export const aboutSectionData = [
	{
		key: "1", icon: <FaAward size={47} className="text-primary mb-3" />, title: "Our Mission", description: "Our Mission is To equip industries with the best-in-class pollution monitoring and automation solutions, enabling them to operate sustainably and protect our planet.", delay: "0.1s"
	},
	{
		key: "2", icon: <FaUsers size={47} className="text-primary mb-3" />, title: "Our Vision", description: "We are driven by a strong vision of a future where industries thrive alongside a healthy environment. Technology can be a powerful tool for positive change, and we are committed to using our expertise to make that vision a reality.", delay: "0.3s"
	}
]

export const factsData = [
	{
		key: "1", count: "17", title: "Happy Clients", delay: "0.1s"
	},
	{
		key: "2", count: "28", title: "Projects Completed", delay: "0.3s"
	},
	{
		key: "3", count: "530786", title: "Liters of water treated", delay: "0.5s"
	},
	{
		key: "4", count: "5840", title: "Cups of coffee", delay: "0.7s"
	}
]

export const productsData = [
	{
		key: "1", image: Slider1, title: "Water Quality Monitoring Systems", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/water-quality-monitoring-systems", delay: "0.1s", icon: <GiWaterTower size={60} className="text-primary" />
	},
	{
		key: "2", image: Product2, title: "ETP/STP Automation (SCADA)", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/etp-stp-automation", delay: "0.3s", icon: <GiLaptop size={60} className="text-primary" />
	},
	{
		key: "3", image: Product3, title: "Coastal Water Monitoring Systems", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/coastal-water-monitoring-systems", delay: "0.5s", icon: <GiWaves size={60} className="text-primary" />
	},
	{
		key: "4", image: Slider3, title: "Aquaculture", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/aquaculture", delay: "0.7s", icon: <GiDoubleFish size={60} className="text-primary" />
	},
	{
		key: "5", image: Product5, title: "Fleet Monitoring Systems for Fishing Boats (GPS and Other Data)", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/fleet-monitoring-systems-for-fishing-boats", delay: "0.9s", icon: <GiFishingBoat size={60} className="text-primary" />
	},
	{
		key: "6", image: Slider4, title: "Smart City Pollution Monitoring Systems", description: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.", buttonName: "Read More", buttonLink: "/products/smart-city-pollution-monitoring-systems", delay: "1.1s", icon: <GiModernCity size={60} className="text-primary" />
	},
]

export const projectCategories = [
	{ id: "1", title: "All", isActive: true }, 
	{ id: "2", title: "Completed Projects", isActive: false }, 
	{ id: "3", title: "Ongoing Projects", isActive: false }
]

export const projectsData = [
	{
		key: "1", image: Slider1, title: "Landscaping", buttonLink: "/", viewButtonLink: "/", delay: "0.1s", icon: <GiWaterTower size={60} className="text-primary" />, categoryId: [1, 2, 3]
	},
	{
		key: "2", image: Product2, title: "Pruning plants", buttonLink: "/", viewButtonLink: "/", delay: "0.3s", icon: <GiLaptop size={60} className="text-primary" />, categoryId: [3]
	},
	{
		key: "3", image: Slider3, title: "Irrigation & Drainage", buttonLink: "/", viewButtonLink: "/", delay: "0.5s", icon: <GiWaves size={60} className="text-primary" />, categoryId: [2, 3]
	},
	{
		key: "4", image: Slider2, title: "Garden Maintenance", buttonLink: "/", viewButtonLink: "/", delay: "0.7s", icon: <GiDoubleFish size={60} className="text-primary" />, categoryId: [1, 3]
	},
	{
		key: "5", image: Product5, title: "Green Technology", buttonLink: "/", viewButtonLink: "/", delay: "0.9s", icon: <GiFishingBoat size={60} className="text-primary" />, categoryId: [1, 2]
	},
	{
		key: "6", image: Slider4, title: "Urban Gardening", buttonLink: "/", viewButtonLink: "/", delay: "1.1s", icon: <GiModernCity size={60} className="text-primary" />, categoryId: [1]
	},
]

export const teamData = [
	{
		key: "1", image: Team2, memberName: "Shashwat Khoont", designation: "Founder & CEO", delay: "0.1s"
	},
	{
		key: "2", image: Team1, memberName: "Nirmal Moliya", designation: "Production Engineer", delay: "0.3s"
	},
	{
		key: "3", image: Team3, memberName: "Vikalp", designation: "Electronics Engineer", delay: "0.5s"
	},
]

export const testimonialData = [
	{
		key: "1", image: Testimonial1, clientMessage: "We have been associated with Dexterous Dynamics since a while now. All our products contain automation from Dexterous Dynamics. We trust them for all our automation needs and will keep working with them.", clientName: "EnvCure Technocrat LLP", clientProfession: "Ahmedabad/Gujarat"
	},
	{
		key: "2", image: Testimonial2, clientMessage: "Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.", clientName: "Client 2", clientProfession: "India"
	},
]

export const testimonialSliderSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	fade: true,
	  cssEase: 'linear',
	arrows: true,
	autoplaySpeed: 100,
	prevArrow: <button type="button" class="slick-custom-arrow slick-prev-testimonial slick-prev">
		<div class="arrow arrow--left">
			<span></span>
		</div>
	</button>,
	nextArrow: <button type="button" class="slick-custom-arrow slick-next-testimonial slick-next">
		<div class="arrow arrow--right">
			<span></span>
		  </div> 
	</button>
};

export const FAQData = [
	{
		key: 1, id: "headingOne", delay: "0.1s", targetId: "collapseOne", question: "What services does Dexterous Dynamics offer?", answer: "We specialize in water treatment plant automation, water quality monitoring, and custom environmental monitoring solutions tailored to your specific needs."	
	},
	{
		key: 2, id: "headingOTwo", delay: "0.3s", targetId: "collapseTwo", question: "How does your water treatment automation improve efficiency?", answer: "Our systems integrate advanced sensors and control technologies to optimize water treatment processes, reduce energy consumption, and minimize manual intervention, resulting in cost savings and improved operational efficiency."	
	},
	{
		key: 3, id: "headingThree", delay: "0.5s", targetId: "collapseThree", question: "Do you offer customized solutions for different industries?", answer: "Yes, we provide tailored solutions for various industries, including municipal water treatment, industrial water management, and environmental monitoring systems, ensuring optimal performance for every application."	
	},
	{
		key: 4, id: "headingFour", delay: "0.7s", targetId: "collapseFour", question: "What kind of support can I expect from Dexterous Dynamics?", answer: "We offer comprehensive support, including system installation, training, maintenance, and technical assistance to ensure seamless operation and long-term reliability of your automation systems."	
	},
	{
		key: 5, id: "headingFive", delay: "0.9s", targetId: "collapseFive", question: "How do you ensure transparency in pricing?", answer: "We believe in clear costing with no hidden fees. Our pricing structure is designed to be straightforward, and we work closely with clients to provide accurate cost estimates upfront based on their project requirements."	
	},
	{
		key: 6, id: "headingSix", delay: "1.1s", targetId: "collapseSix", question: "What is ambient air quality monitoring, and why is it important?", answer: "Ambient air quality monitoring measures the concentration of pollutants in the air to ensure environmental safety and compliance with regulatory standards. Monitoring helps identify pollution sources and assess their impact on public health and the environment."	
	},
	{
		key: 7, id: "headingSeven", delay: "1.3s", targetId: "collapseSeven", question: "What pollutants can your air quality monitoring systems detect?", answer: "Our air quality monitoring devices can detect a range of pollutants, including particulate matter (PM1.0, PM2.5, PM10), gases like CO2, volatile organic compounds (TVOCs), and more along with the basic temperature, humidity and pressure. We offer customizable solutions based on your specific needs."	
	},
	{
		key: 8, id: "headingEight", delay: "1.5s", targetId: "collapseEight", question: "How accurate are Dexterous Dynamics' air quality sensors?", answer: "Our air quality sensors are highly sensitive and accurate, providing real-time data for continuous monitoring. They meet industry standards for precision and reliability, ensuring that you get actionable insights for air quality management."	
	},
	{
		key: 9, id: "headingNine", delay: "1.7s", targetId: "collapseNine", question: "Can your air monitoring system be integrated with existing infrastructure?", answer: "Yes, our ambient air monitoring systems are designed to integrate seamlessly with existing infrastructure, providing you with real-time data and analytics without the need for major modifications."	
	},
	{
		key: 10, id: "headingTen", delay: "1.9s", targetId: "collapseTen", question: "Who can benefit from your ambient air monitoring solutions?", answer: "Our solutions are ideal for industries, municipalities, and environmental agencies looking to monitor air quality for regulatory compliance, environmental impact assessments, and ensuring safe living conditions for communities."	
	},
]

export const blogDetails = [
	{
		key: 1, id: "blog-1", title: "You will vainly look for fruit on it in autumn.", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog1, delay: "0.1s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
	{
		key: 2, id: "blog-2", title: "A man's worth has its season, like tomato.", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog2, delay: "0.3s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
	{
		key: 3, id: "blog-3", title: "Good thoughts bear good fresh juicy fruit.", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog3, delay: "0.5s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
	{
		key: 4, id: "blog-4", title: "Fall in love with the fresh orange", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog4, delay: "0.7s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
	{
		key: 5, id: "blog-5", title: "Why the berries always look delecious", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog5, delay: "0.9s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
	{
		key: 6, id: "blog-6", title: "Love for fruits are genuine of John Doe", date: "27 December, 2019", content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus laborum autem, dolores inventore, beatae nam.", image: Blog6, delay: "1.1s", detailContent: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint soluta, similique quidem fuga vel voluptates amet doloremque corrupti. Perferendis totam voluptates eius error fuga cupiditate dolorum? Adipisci mollitia quod labore aut natus nobis. Rerum perferendis, nobis hic adipisci vel inventore facilis rem illo, tenetur ipsa voluptate dolorem, cupiditate temporibus laudantium quidem recusandae expedita dicta cum eum. Quae laborum repellat a ut, voluptatum ipsa eum. Culpa fugiat minus laborum quia nam!</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, praesentium, dicta. Dolorum inventore molestias velit possimus, dolore labore aliquam aperiam architecto quo reprehenderit excepturi ipsum ipsam accusantium nobis ducimus laudantium.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum est aperiam voluptatum id cupiditate quae corporis ex. Molestias modi mollitia neque magni voluptatum, omnis repudiandae aliquam quae veniam error! Eligendi distinctio, ab eius iure atque ducimus id deleniti, vel alias sint similique perspiciatis saepe necessitatibus non eveniet, quo nisi soluta.</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt beatae nemo quaerat, doloribus obcaecati odio!</p>`
	},
]
import { useRef, useEffect } from "react";

const useTitle = (title) => {
  const documentDefined = typeof document !== "undefined";
  const originalTitle = useRef(documentDefined ? document.title : null);

  useEffect(() => {
    if (!documentDefined) return;

    if (document.title !== title) {
      document.title = title;
    }

    return () => {
      let originalTitleRef = originalTitle.current
      document.title = originalTitleRef;
    };
  }, [title]);
};

export default useTitle;
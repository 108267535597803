import { FaCalendar } from "react-icons/fa6";
import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';
import { getDetailFromId } from "../../helper";
import { blogDetails } from "../../constants";

const BlogDetailPage = () => {
	const { id } = useParams();
	const singleBlogDetails = getDetailFromId(blogDetails, id, "id");
	const {title, date, detailContent, image} = singleBlogDetails
	return(
		<Layout>
			<div class="container-xxl py-5 mt-150 mb-150">
				<div class="container">
					<div class="row">
						<div class="col-lg-8">
							<div class="single-article-section">
								<div class="single-article-text">
									<img src={image} className="single-artcile-bg" alt="" />
									<p class="blog-meta">
										<span class="date"><FaCalendar className="me-2" />{date}</span>
									</p>
									<h2>{title}</h2>
									<p dangerouslySetInnerHTML={{ __html: detailContent }} />
								</div>

								{/* <div class="comments-list-wrap">
									<h3 class="comment-count-title">3 Comments</h3>
									<div class="comment-list">
										<div class="single-comment-body">
											<div class="comment-user-avater">
												<img src="assets/img/avaters/avatar1.png" alt="" />
											</div>
											<div class="comment-text-body">
												<h4>Jenny Joe <span class="comment-date">Aprl 26, 2020</span> <a href="#">reply</a></h4>
												<p>Nunc risus ex, tempus quis purus ac, tempor consequat ex. Vivamus sem magna, maximus at est id, maximus aliquet nunc. Suspendisse lacinia velit a eros porttitor, in interdum ante faucibus Suspendisse lacinia velit a eros porttitor, in interdum ante faucibus.</p>
											</div>
											<div class="single-comment-body child">
												<div class="comment-user-avater">
													<img src="assets/img/avaters/avatar3.png" alt="" />
												</div>
												<div class="comment-text-body">
													<h4>Simon Soe <span class="comment-date">Aprl 27, 2020</span> <a href="#">reply</a></h4>
													<p>Nunc risus ex, tempus quis purus ac, tempor consequat ex. Vivamus sem magna, maximus at est id, maximus aliquet nunc. Suspendisse lacinia velit a eros porttitor, in interdum ante faucibus.</p>
												</div>
											</div>
										</div>
										<div class="single-comment-body">
											<div class="comment-user-avater">
												<img src="assets/img/avaters/avatar2.png" alt="" />
											</div>
											<div class="comment-text-body">
												<h4>Addy Aoe <span class="comment-date">May 12, 2020</span> <a href="#">reply</a></h4>
												<p>Nunc risus ex, tempus quis purus ac, tempor consequat ex. Vivamus sem magna, maximus at est id, maximus aliquet nunc. Suspendisse lacinia velit a eros porttitor, in interdum ante faucibus Suspendisse lacinia velit a eros porttitor, in interdum ante faucibus.</p>
											</div>
										</div>
									</div>
								</div>

								<div class="comment-template">
									<h4>Leave a comment</h4>
									<p>If you have a comment dont feel hesitate to send us your opinion.</p>
									<form action="index.html">
										<p>
											<input type="text" placeholder="Your Name" />
											<input type="email" placeholder="Your Email" />
										</p>
										<p><textarea name="comment" id="comment" cols="30" rows="10" placeholder="Your Message"></textarea></p>
										<p><input type="submit" value="Submit" /></p>
									</form>
								</div> */}
							</div>
						</div>
						<div class="col-lg-4">
							<div class="sidebar-section">
								<div class="recent-posts">
									<h4>Recent Posts</h4>
									<ul>
										{
											blogDetails.map((i) => (
												<li><a href={`/blog/${i.id}`}>{i.title}</a></li>
											))
										}
									</ul>
								</div>
								<div class="archive-posts">
									<h4>Archive Posts</h4>
									<ul>
										<li><a href="#">JAN 2019 (5)</a></li>
										<li><a href="#">FEB 2019 (3)</a></li>
										<li><a href="#">MAY 2019 (4)</a></li>
										<li><a href="#">SEP 2019 (4)</a></li>
										<li><a href="#">DEC 2019 (3)</a></li>
									</ul>
								</div>
								<div class="tag-section">
									<h4>Tags</h4>
									<ul>
										<li><a href="#">Apple</a></li>
										<li><a href="#">Strawberry</a></li>
										<li><a href="#">BErry</a></li>
										<li><a href="#">Orange</a></li>
										<li><a href="#">Lemon</a></li>
										<li><a href="#">Banana</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default BlogDetailPage;